<template>
  <div class="featureDuration" style="margin: -15px -15px;background-color: #f0f2f5;min-height: 600px;border: 10px solid #EDEFF2;">
    <a-spin :spinning="spinning">
    <div class="featureDuration-title">{{titleTop}}</div>
    <div class="featureDuration-card">
      <a-tabs default-active-key="1" @change="tabs_callback">
        <a-tab-pane key="1" tab="原始观看记录（自动上报）">
        </a-tab-pane>
        <a-tab-pane key="2" tab="人员观看总时长（自动上报）" force-render>
        </a-tab-pane>
        <a-tab-pane key="3" tab="原始观看记录（手动上报）">
        </a-tab-pane>
        <a-tab-pane key="4" tab="人员观看总时长（手动上报）">
        </a-tab-pane>
        <a-tab-pane key="5" tab="聊天室记录">
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="featureDuration_content">
      <div class="featureDuration_content_top">
        <span v-show="no_chat">观看类型</span>
        <a-select
            v-show="no_chat"
            show-search
            placeholder="请选择观看类型"
            option-filter-prop="children"
            style="width: 300px;margin-left: 10px;"
            v-model="watch_type"
        >
          <a-select-option
              v-for="(type,index) in watch_type_foem"
              @click="select_handleChange_left"
              :value="type.type">
            {{ type.typeName }}
          </a-select-option>
        </a-select>
        <span style="margin-left: 20px;">直播间</span>
        <a-select
            show-search
            placeholder="请选择直播间"
            option-filter-prop="children"
            style="width: 300px;margin-left: 10px;"
            v-model="live_type"
        >
          <a-select-option
              v-for="(live,index) in live_type_foem"
              @click="select_handleChange_right(live)"
              :value="live.id">
            {{ live.title }}
          </a-select-option>
        </a-select>
        <a-button
            type="primary"
            style="margin-left: 20px"
            @click="lookBtn"
        >查询</a-button>
        <a-button
            @click="restBtn"
            style="margin-left: 10px">重置</a-button>
      </div>
      <div v-show="no_chat" class="featureDuration_content_top_btn">
        <a-button
            @click="Export_all"
            class="font"><a-icon class="icon" type="file-excel" />导出全部</a-button>
        <a-button
            @click="CreditsRegistered"
            class="font"><a-icon class="icon" type="file-excel" />学分注册名单时长</a-button>
        <a-button
            @click="RegistrationPayment "
            class="font"><a-icon class="icon" type="file-excel" />报名缴费名单时长</a-button>
      </div>
      <div class="featureDuration_content_table">

        <!-- 原始观看记录（自动上报）-->
        <div v-show="tab==='1'">
          <a-table
              style="margin-top: 20px;width: 98%;"
              :columns="columns_Original_viewing_voluntarily"
              :rowKey='record=>record.id'
              :pagination="false"
              :data-source="data_Original_viewing_voluntarily">
           <span slot="startTime" slot-scope="text, record">
              <div v-if="record.startTime">
                {{(record.startTime).slice(0,10)}}
              </div>
             <div v-else></div>
            </span>
            <span slot="type" slot-scope="text, record">
              <div v-if="record.type === 'LIVE'">
                观看直播
              </div><div v-if="record.type === 'VOD'">
                观看点播
              </div><div v-if="record.type === 'DBHF'">
               观看点播回放
              </div>
            </span>
            <span slot="startTime" slot-scope="text, record">
              <div v-if="record.startTime">
                {{(record.startTime).slice(0,10)}}
              </div>
             <div v-else></div>
            </span>
          </a-table>
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 15px">
              <a-pagination
                  show-quick-jumper
                  :current="tableCount_Original_viewing_voluntarily_page_no"
                  @change="listChange_Original_viewing_voluntarily"
                  :total="tableCount_Original_viewing_voluntarily" />
            </div>
          </div>
        </div>
        <!-- 人员观看总时长（自动上报）-->
        <div v-show="tab==='2'">
          <a-table
              style="margin-top: 20px;width: 98%;"
              :columns="columns_People_watch_voluntarily"
              :rowKey='record=>record.id'
              :pagination="false"
              :data-source="data_People_watch_voluntarily">
            <span slot="type" slot-scope="text, record">
              <div v-if="record.type === 'LIVE'">
                观看直播
              </div><div v-if="record.type === 'VOD'">
                观看点播
              </div><div v-if="record.type === 'DBHF'">
               观看点播回放
              </div>
            </span>
          </a-table>
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 15px">
              <a-pagination
                  show-quick-jumper
                  :current="tableCount_Original_viewing_operation_page_no"
                  @change="listChange_People_watch_voluntarily"
                  :total="tableCount_People_watch_voluntarily" />
            </div>
          </div>
        </div>
        <!-- 原始观看记录（手动上报）-->
        <div v-show="tab==='3'">
          <a-table
              style="margin-top: 20px;width: 98%;"
              :columns="columns_Original_viewing_operation"
              :rowKey='record=>record.id'
              :pagination="false"
              :data-source="data_Original_viewing_operation">
            <span slot="startTime" slot-scope="text, record">
              <div v-if="record.startTime">
                {{(record.startTime).slice(0,10)}}
              </div>
             <div v-else></div>
            </span>
            <span slot="startTime" slot-scope="text, record">
              <div v-if="record.startTime">
                {{(record.startTime).slice(0,10)}}
              </div>
             <div v-else></div>
            </span>
            <span slot="type" slot-scope="text, record">
              <div v-if="record.type === 'LIVE'">
                观看直播
              </div><div v-if="record.type === 'VOD'">
                观看点播
              </div><div v-if="record.type === 'DBHF'">
               观看点播回放
              </div>
            </span>
          </a-table>
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 15px">
              <a-pagination
                  show-quick-jumper
                  :current="tableCount_Original_viewing_operation_page_no"
                  @change="listChange_Original_viewing_operation"
                  :total="tableCount_Original_viewing_operation" />
            </div>
          </div>
        </div>
        <!-- 人员观看总时长（手动上报）-->
        <div v-show="tab==='4'">
          <a-table
              style="margin-top: 20px;width: 98%;"
              :columns="columns_People_watch_operation"
              :rowKey='record=>record.id'
              :pagination="false"
              :data-source="data_People_watch_operation">
            <span slot="type" slot-scope="text, record">
              <div v-if="record.type === 'LIVE'">
                观看直播
              </div><div v-if="record.type === 'VOD'">
                观看点播
              </div><div v-if="record.type === 'DBHF'">
               观看点播回放
              </div>
            </span>
          </a-table>
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 15px">
              <a-pagination
                  show-quick-jumper
                  :current="tableCount_People_watch_operation_page_no"
                  @change="listChange_People_watch_operation"
                  :total="tableCount_People_watch_operation" />
            </div>
          </div>
        </div>
        <!-- 聊天室记录-->
        <div v-show="tab==='5'">
          <a-table
              style="margin-top: 20px;width: 98%;"
              :columns="columns_chatting_records "
              :rowKey='record=>record.id'
              :pagination="false"
              :data-source="data_chatting_records">
           <span slot="terminal" slot-scope="text, record">
             <a-popconfirm
                 title="是否确认删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="delConfirm(record.id)"
             >
                 <a >删除</a>
            </a-popconfirm>
              <a
                  @click="lookBtn_list(record)"
                  style="margin-left: 10px">查看</a>
            </span>
            <span slot="vodHide" slot-scope="text, record">
             <a-switch
                 v-model="record.vodHide ===1 ?true:false"
                 checked-children="是"
                 un-checked-children="否"
                 @change="selectSwitch(record.id,record.vodHide === 1? 0 : 1)"
                 default-checked />
            </span>
          </a-table>
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 15px">
              <a-pagination
                  show-quick-jumper
                  :default-current="tableCount_chatting_records_page_no"
                  @change="listChange_chatting_records"
                  :total="tableCount_chatting_records" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部   -->
    <div style="height: 60px"></div>
    <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
      <div
          style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
        <a-button @click="returnBtn">返回上一层</a-button>
      </div>
    </div>
    </a-spin>
  </div>
</template>

<script>
import {
  delList,
  get_chatting_records,
  get_Original_viewing,
  get_Original_viewing_operation,
  get_People_watch,
  get_People_watch_operation, getSetLiveList, putVodHide
} from "../../../service/liveMeet_api";
import {
  CaseLiveOneList,
  getCase_chatting_records_exal,
  getCase_Original_viewing_operation_exal,
  getCase_Original_viewing_voluntarily_exal, getCase_People_watch_operation_exal,
  getCase_People_watch_voluntarily_exal,
  getLiveBroadcast
} from "../../../service/MedicalConference";

export default {
  data(){
    return{
      titleTop:'',
      spinning:false,
      watch_type:undefined,
      watch_type_foem:[
        // LIVE：直播 VOD：点播 DBHF：点播回放
        {
          id:'1',
          type:'LIVE',
          typeName:'直播'
        },{
          id:'2',
          type:'VOD',
          typeName:'点播'
        },{
          id:'3',
          type:'DBHF',
          typeName:'点播回放'
        },
      ],
      live_type:undefined,
      live_type_foem:[],
      tab:'1',
    //  原始观看记录（自动上报）
      columns_Original_viewing_voluntarily: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: '8%',
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
          key: 'hospitalName',
          width: '15%',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
          key: 'hospitalOffices',
          ellipsis: true,
          width: '10%',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          key: 'jobTitle',
          ellipsis: true,
          width: '8%',
        },
        {
          title: '身份',
          dataIndex: 'memberType',
          key: 'memberType',
          ellipsis: true,
          width: '8%',
        },
        {
          title: '观看日期',
          dataIndex: 'startTime',
          key: 'startTime',
          ellipsis: true,
          width: '10%',
          scopedSlots: { customRender: 'startTime' },
        },
        {
          title: '观看时间',
          dataIndex: 'endTime',
          key: 'endTime',
          ellipsis: true,
          width: '15%',
          scopedSlots: { customRender: 'endTime' },
        },
        {
          title: 'IP地址',
          dataIndex: 'ip',
          key: 'ip',
          ellipsis: true,
          width: '12%',
        },
        {
          title: '观看类型',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          width: '8%',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '终端',
          dataIndex: 'channel',
          key: 'channel',
          ellipsis: true,
          width: '8%',
        },
      ],
      data_Original_viewing_voluntarily:[],
      tableCount_Original_viewing_voluntarily:0,
      tableCount_Original_viewing_voluntarily_page_no:1,

      //  原始观看记录（手动上报）
      columns_Original_viewing_operation: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: '8%',
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
          key: 'hospitalName',
          width: '15%',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
          key: 'hospitalOffices',
          ellipsis: true,
          width: '10%',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          key: 'jobTitle',
          ellipsis: true,
          width: '8%',
        },
        {
          title: '身份',
          dataIndex: 'memberType',
          key: 'memberType',
          ellipsis: true,
          width: '8%',
        },
        {
          title: '观看日期',
          dataIndex: 'startTime',
          key: 'startTime',
          ellipsis: true,
          width: '10%',
          scopedSlots: { customRender: 'startTime' },
        },
        {
          title: '观看时间',
          dataIndex: 'endTime',
          key: 'endTime',
          ellipsis: true,
          width: '15%',
          scopedSlots: { customRender: 'endTime' },
        },
        {
          title: 'IP地址',
          dataIndex: 'ip',
          key: 'ip',
          ellipsis: true,
          width: '12%',
        },
        {
          title: '观看类型',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          width: '8%',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '终端',
          dataIndex: 'channel',
          key: 'channel',
          ellipsis: true,
          width: '8%',
        },
      ],
      data_Original_viewing_operation:[],
      tableCount_Original_viewing_operation:0,
      tableCount_Original_viewing_operation_page_no:1,


    // 人员观看总时长（自动上报）
      columns_People_watch_voluntarily :[
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: '8%',
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
          key: 'hospitalName',
          width: '15%',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
          key: 'hospitalOffices',
          ellipsis: true,
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          key: 'jobTitle',
          ellipsis: true,
        },
        {
          title: '身份',
          dataIndex: 'memberType',
          key: 'memberType',
          ellipsis: true,
        },
        {
          title: '观看时长(分钟)',
          dataIndex: 'duration',
          key: 'duration',
          ellipsis: true,
        },
        {
          title: 'IP地址',
          dataIndex: 'ip',
          key: 'ip',
          ellipsis: true,
        },
        {
          title: '观看类型',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
        },
      ],
      data_People_watch_voluntarily:[],
      tableCount_People_watch_voluntarily:0,
      tableCount_People_watch_voluntarily_page_no:1,


      // 人员观看总时长（手动上报）
      columns_People_watch_operation :[
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: '8%',
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
          key: 'hospitalName',
          width: '15%',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
          key: 'hospitalOffices',
          ellipsis: true,
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          key: 'jobTitle',
          ellipsis: true,
        },
        {
          title: '身份',
          dataIndex: 'memberType',
          key: 'memberType',
          ellipsis: true,
        },
        {
          title: '观看时长(分钟)',
          dataIndex: 'duration',
          key: 'duration',
          ellipsis: true,
        },
        {
          title: 'IP地址',
          dataIndex: 'ip',
          key: 'ip',
          ellipsis: true,
        },
        {
          title: '观看类型',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
        },
      ],
      data_People_watch_operation:[],
      tableCount_People_watch_operation:0,
      tableCount_People_watch_operation_page_no:1,

    //  聊天
      columns_chatting_records: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: '手机号',
          dataIndex: 'tel',
          key: 'tel',
          // scopedSlots: { customRender: 'tel' },
        },
        {
          title: '发送内容',
          dataIndex: 'message',
          key: 'message',
          width:'40%'
          // scopedSlots: { customRender: 'message' },
        },
        {
          title: '点播隐藏',
          dataIndex: 'vodHide',
          key: 'vodHide',
          ellipsis: true,
          scopedSlots: { customRender: 'vodHide' },
        },
        {
          title: '操作',
          dataIndex: 'terminal',
          key: 'terminal',
          ellipsis: true,
          scopedSlots: { customRender: 'terminal' },
        },
      ],
      data_chatting_records:[],
      tableCount_chatting_records:0,
      tableCount_chatting_records_page_no:1,
      no_chat:true,

    }
  },
  created() {
    //当tabs导航在第一个状态下时加载
    if(this.tab === '1'){
      this.getOriginalViewingVoluntarilyList()
    }
    this.getLiveList()
    this.getCaseOneList()
  },
  methods:{
    //获取医学会议标题和地址
    async getCaseOneList() {
      const response = await CaseLiveOneList(this.$route.query.id)
      if(response.code === 0){
        this.titleTop = response.data.title
      }else {
        this.$message.warning("失败!"+response.message)
      }
    },
    //获取直播间列表
    async getLiveList() {
      this.spinning = true
      const response = await getLiveBroadcast(this.$route.query.id,'','999')
      if(response.code === 0){
        this.live_type_foem = response.data.rows
      }else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    //获取列表
    //  原始观看记录（自动上报）-----------------
    async getOriginalViewingVoluntarilyList(type,streamId,page_no) {
      this.spinning = true
      let data = {
        conventionId:this.$route.query.id,
        type:type,
        streamId:streamId,
        page_no:page_no
      }
      const response = await get_Original_viewing(data)
      if(response.code === 0){
        this.data_Original_viewing_voluntarily = response.data.rows
        this.tableCount_Original_viewing_voluntarily = response.data.count
      }else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    //  原始观看记录（手动上报）------------------
    async getOriginalViewingOperationList(type,streamId,page_no) {
      this.spinning = true
      let data = {
        conventionId:this.$route.query.id,
        type:type,
        streamId:streamId,
        page_no:page_no
      }
      const response = await get_Original_viewing_operation(data)
      if(response.code === 0){
        this.data_Original_viewing_operation = response.data.rows
        this.tableCount_Original_viewing_operation = response.data.count
      }else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    //  人员观看总时长（自动上报）-----------------
    async getPeopleWatchVoluntarilyList(type,streamId,page_no) {
      this.spinning = true
      let data = {
        conventionId:this.$route.query.id,
        type:type,
        streamId:streamId,
        page_no:page_no
      }
      const response = await get_People_watch(data)
      if(response.code === 0){
        this.data_People_watch_voluntarily = response.data.rows
        this.tableCount_People_watch_voluntarily = response.data.count
      }else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    //  人员观看总时长（手动上报）-----------------
    async getPeopleWatchOperationList(type,streamId,page_no) {
      this.spinning = true
      let data = {
        conventionId:this.$route.query.id,
        type:type,
        streamId:streamId,
        page_no:page_no
      }
      const response = await get_People_watch_operation(data)
      if(response.code === 0){
        this.data_People_watch_operation = response.data.rows
        this.tableCount_People_watch_operation = response.data.count
      }else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    //聊天--------------------------------------
    async getChatList(type,streamId,page_no) {
      this.spinning = true
      let data = {
        conventionId:this.$route.query.id,
        type:type,
        streamId:streamId,
        page_no:page_no
      }
      const response = await get_chatting_records(data)
      if(response.code === 0){
        this.data_chatting_records = response.data.rows
        this.tableCount_chatting_records = response.data.count
      }else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    async delConfirm(id) {
      let liveChatIds = [id]
      const response = await delList(liveChatIds)
      if (response.code === 0) {
        this.$message.success("操作成功！")
        //  刷新列表
        await this.getChatList(this.watch_type, this.live_type, this.tableCount_chatting_records_page_no)

      } else {
        this.$message.warning("失败" + response.message)
      }
    },
    lookBtn_list(){

    },
    async selectSwitch(id, isOpen) {
      let data = {
        "vodHide":isOpen
      }
      const response = await putVodHide(id,data)
      if(response.code === 0){
      //  刷新列表
        await this.getChatList(this.watch_type, this.live_type, this.tableCount_chatting_records_page_no)
      }else {
        this.$message.warning("失败"+response.message)
      }
    },

    returnBtn(){
      window.history.back()
    },
    tabs_callback(key){
      this.tab = key
      //清空
      this.watch_type = undefined
      this.live_type = undefined
      //恢复页码1
      this.tableCount_Original_viewing_voluntarily_page_no=1
      this.tableCount_Original_viewing_operation_page_no=1
      this.tableCount_People_watch_voluntarily_page_no=1
      this.tableCount_People_watch_operation_page_no=1
      this.tableCount_chatting_records_page_no=1
      switch (key){
        //原始观看记录（自动上报）
        case '1':
          this.no_chat = true
          this.getOriginalViewingVoluntarilyList()
          break;
          //人员观看总时长（自动上报）
        case '2':
          this.no_chat = true
          this.getPeopleWatchVoluntarilyList()
          break;
          // 原始观看记录（手动上报）
        case '3':
          this.no_chat = true
          this.getOriginalViewingOperationList()
          break;
        //  人员观看总时长（手动上报）
        case '4':
          this.no_chat = true
          this.getPeopleWatchOperationList()
          break;
        //  聊天室记录
        case '5':
          this.no_chat = false
          this.getChatList()
          break;
        default:
          break;
      }
    },
    select_handleChange_left(val){
      this.watch_type = val.key
      let type = this.watch_type
      let streamId = this.live_type
      switch (this.tab){
          //原始观看记录（自动上报）
        case '1':
          let page_no1 = this.tableCount_Original_viewing_voluntarily_page_no
          this.getOriginalViewingVoluntarilyList(type,streamId,page_no1)
          break;
          //人员观看总时长（自动上报）
        case '2':
          let page_no2 = this.tableCount_People_watch_voluntarily_page_no
          this.getPeopleWatchVoluntarilyList(type,streamId,page_no2)
          break;
          // 原始观看记录（手动上报）
        case '3':
          let page_no3 = this.tableCount_Original_viewing_operation_page_no
          this.getOriginalViewingOperationList(type,streamId,page_no3)
          break;
          //  人员观看总时长（手动上报）
        case '4':
          let page_no4 = this.tableCount_People_watch_operation_page_no
          this.getPeopleWatchOperationList(type,streamId,page_no4)
          break;
          //  聊天室记录
        case '5':
          let page_no5 = this.tableCount_chatting_records_page_no
          this.getChatList(type,streamId,page_no5)
          break;
        default:
          break;
      }
    },
    select_handleChange_right(live){
      this.live_type = live.id
      let type = this.watch_type
      let streamId = this.live_type
      switch (this.tab){
          //原始观看记录（自动上报）
        case '1':
          let page_no1 = this.tableCount_Original_viewing_voluntarily_page_no
          this.getOriginalViewingVoluntarilyList(type,streamId,page_no1)
          break;
          //人员观看总时长（自动上报）
        case '2':
          let page_no2 = this.tableCount_People_watch_voluntarily_page_no
          this.getPeopleWatchVoluntarilyList(type,streamId,page_no2)
          break;
          // 原始观看记录（手动上报）
        case '3':
          let page_no3 = this.tableCount_Original_viewing_operation_page_no
          this.getOriginalViewingOperationList(type,streamId,page_no3)
          break;
          //  人员观看总时长（手动上报）
        case '4':
          let page_no4 = this.tableCount_People_watch_operation_page_no
          this.getPeopleWatchOperationList(type,streamId,page_no4)
          break;
          //  聊天室记录
        case '5':
          let page_no5 = this.tableCount_chatting_records_page_no
          this.getChatList(type,streamId,page_no5)
          break;
        default:
          break;
      }
    },
    lookBtn(){
      let type = this.watch_type
      let streamId = this.live_type
      switch (this.tab){
          //原始观看记录（自动上报）
        case '1':
          let page_no1 = this.tableCount_Original_viewing_voluntarily_page_no
          this.getOriginalViewingVoluntarilyList(type,streamId,page_no1)
          break;
          //人员观看总时长（自动上报）
        case '2':
          let page_no2 = this.tableCount_People_watch_voluntarily_page_no
          this.getPeopleWatchVoluntarilyList(type,streamId,page_no2)
          break;
          // 原始观看记录（手动上报）
        case '3':
          let page_no3 = this.tableCount_Original_viewing_operation_page_no
          this.getOriginalViewingOperationList(type,streamId,page_no3)
          break;
          //  人员观看总时长（手动上报）
        case '4':
          let page_no4 = this.tableCount_People_watch_operation_page_no
          this.getPeopleWatchOperationList(type,streamId,page_no4)
          break;
          //  聊天室记录
        case '5':
          let page_no5 = this.tableCount_chatting_records_page_no
          this.getChatList(type,streamId,page_no5)
          break;
        default:
          break;
      }
    },



    //导出全部--------------------------------------------------
    //原始观看记录（自动上报）
    async Export_all_public_Original_viewing_voluntarily(data){
      this.spinning = true
      const response = await getCase_Original_viewing_voluntarily_exal(data)
      this.spinning = false
    },
    //人员观看总时长（自动上报）
    async Export_all_public_People_watch_voluntarily(data) {
      this.spinning = true
      const response = await getCase_People_watch_voluntarily_exal(data)
      this.spinning = false
    },
    //原始观看记录（手动上报）
    async Export_all_public_Original_viewing_operation(data) {
      this.spinning = true
      const response = await getCase_Original_viewing_operation_exal(data)
      this.spinning = false
    },
    //人员观看总时长（手动上报）
    async Export_all_People_watch_operation(data) {
      this.spinning = true
      const response = await getCase_People_watch_operation_exal(data)
      this.spinning = false
    },
    //聊天室记录
    async Export_all_chatting_records(data) {
      this.spinning = true
      const response = await getCase_chatting_records_exal(data)
      this.spinning = false
    },

    Export_all(){
      let type = this.watch_type
      let streamId = this.live_type
      switch (this.tab){
          //原始观看记录（自动上报）
        case '1':
          let data = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            ExelName:'原始观看记录（自动上报）表'
          }
          this.Export_all_public_Original_viewing_voluntarily(data)
          break;
          //人员观看总时长（自动上报）
        case '2':
          let data2 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            ExelName:'人员观看总时长（自动上报）表'
          }
          this.Export_all_public_People_watch_voluntarily(data2)
          break;
          // 原始观看记录（手动上报）
        case '3':
          let data3 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            ExelName:'原始观看记录（手动上报）表'
          }
          this.Export_all_public_Original_viewing_operation(data3)
          break;
          //  人员观看总时长（手动上报）
        case '4':
          let data4 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            ExelName:'人员观看总时长（手动上报）表'
          }
          this.Export_all_People_watch_operation(data4)
          break;
          //  聊天室记录
        case '5':
          let data5 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            ExelName:'聊天室记录表'
          }
          this.Export_all_chatting_records(data5)
          break;
        default:
          break;
      }
    },

    //学分注册-导出--------------------------------------------------------
    CreditsRegistered() {
      let type = this.watch_type
      let streamId = this.live_type
      switch (this.tab){
          //原始观看记录（自动上报）
        case '1':
          let data = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            isConventionEnrollRecords:1,
            ExelName:'原始观看记录（自动上报）--学分注册名单表'
          }
          this.Export_all_public_Original_viewing_voluntarily(data)
          break;
          //人员观看总时长（自动上报）
        case '2':
          let data2 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            isConventionEnrollRecords:1,
            ExelName:'人员观看总时长（自动上报）--学分注册名单表'
          }
          this.Export_all_public_People_watch_voluntarily(data2)
          break;
          // 原始观看记录（手动上报）
        case '3':
          let data3 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            isConventionEnrollRecords:1,
            ExelName:'原始观看记录（手动上报）--学分注册名单表'
          }
          this.Export_all_public_Original_viewing_operation(data3)
          break;
          //  人员观看总时长（手动上报）
        case '4':
          let data4 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            isConventionEnrollRecords:1,
            ExelName:'人员观看总时长（手动上报）--学分注册名单表'
          }
          this.Export_all_People_watch_operation(data4)
          break;
        default:
          break;
      }
    },

    //缴费名单-导出--------------------------------------------------------
    RegistrationPayment() {
      let type = this.watch_type
      let streamId = this.live_type
      switch (this.tab){
          //原始观看记录（自动上报）
        case '1':
          let data = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            isCreditRecords:1,
            ExelName:'原始观看记录（自动上报）--注册缴费名单名单表'
          }
          this.Export_all_public_Original_viewing_voluntarily(data)
          break;
          //人员观看总时长（自动上报）
        case '2':
          let data2 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            isCreditRecords:1,
            ExelName:'人员观看总时长（自动上报）--注册缴费名单名单表'
          }
          this.Export_all_public_People_watch_voluntarily(data2)
          break;
          // 原始观看记录（手动上报）
        case '3':
          let data3 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            isCreditRecords:1,
            ExelName:'原始观看记录（手动上报）--注册缴费名单名单表'
          }
          this.Export_all_public_Original_viewing_operation(data3)
          break;
          //  人员观看总时长（手动上报）
        case '4':
          let data4 = {
            conventionId:this.$route.query.id,
            type:type,
            streamId:streamId,
            isCreditRecords:1,
            ExelName:'人员观看总时长（手动上报）--注册缴费名单名单表'
          }
          this.Export_all_People_watch_operation(data4)
          break;
        default:
          break;
      }
    },

    restBtn(){
      this.watch_type = undefined
      this.live_type = undefined
      let type = this.watch_type
      let streamId = this.live_type
      //恢复页码1
      this.tableCount_Original_viewing_voluntarily_page_no=1
      this.tableCount_Original_viewing_operation_page_no=1
      this.tableCount_People_watch_voluntarily_page_no=1
      this.tableCount_People_watch_operation_page_no=1
      this.tableCount_chatting_records_page_no=1
      switch (this.tab){
          //原始观看记录（自动上报）
        case '1':
          let page_no1 = this.tableCount_Original_viewing_voluntarily_page_no
          this.getOriginalViewingVoluntarilyList(type,streamId,page_no1)
          break;
          //人员观看总时长（自动上报）
        case '2':
          let page_no2 = this.tableCount_People_watch_voluntarily_page_no
          this.getPeopleWatchVoluntarilyList(type,streamId,page_no2)
          break;
          // 原始观看记录（手动上报）
        case '3':
          let page_no3 = this.tableCount_Original_viewing_operation_page_no
          this.getOriginalViewingOperationList(type,streamId,page_no3)
          break;
          //  人员观看总时长（手动上报）
        case '4':
          let page_no4 = this.tableCount_People_watch_operation_page_no
          this.getPeopleWatchOperationList(type,streamId,page_no4)
          break;
          //  聊天室记录
        case '5':
          let page_no5 = this.tableCount_chatting_records_page_no
          this.getChatList(type,streamId,page_no5)
          break;
        default:
          break;
      }
    },

    //分页--------------------------------------------------------------------------------------
    //  原始观看记录（自动上报）-----------------------------------------
    listChange_Original_viewing_voluntarily(page_no){
      this.tableCount_Original_viewing_voluntarily_page_no = page_no
      this.getOriginalViewingVoluntarilyList(this.watch_type,this.live_type,page_no)
    },
    //  原始观看记录（手动上报）-----------------------------------------
    listChange_Original_viewing_operation(page_no){
      this.tableCount_Original_viewing_operation_page_no = page_no
      this.getOriginalViewingOperationList(this.watch_type,this.live_type,page_no)
    },
    //  人员观看总时长（自动上报）---------------------------------------
    listChange_People_watch_voluntarily(page_no){
      this.tableCount_People_watch_voluntarily_page_no = page_no
      this.getPeopleWatchVoluntarilyList(this.watch_type,this.live_type,page_no)
    },

    //  人员观看总时长（手动上报）---------------------------------------
    listChange_People_watch_operation(page_no){
      this.tableCount_People_watch_operation_page_no = page_no
      this.getPeopleWatchOperationList(this.watch_type,this.live_type,page_no)
    },
    //聊天
    listChange_chatting_records(page_no){
      this.tableCount_chatting_records_page_no = page_no
      this.getChatList(this.watch_type,this.live_type,page_no)
    },

  }
}
</script>

<style lang="scss" scoped>
.featureDuration-title{
  height: 50px;
  line-height: 50px;
  color: black;
  font-weight: bold;
  padding-left: 15px;
  font-size: 16px;
  background-color: #fff;
}
.featureDuration-card{
  background-color: #fff;
}
.featureDuration_content{
  width: 100%;
  background-color: #fff;
  border-top: 15px solid #f0f2f5;
  .featureDuration_content_top{
    min-height: 40px;
    margin-top: 15px;
    margin-left: 40px;
  }
  .featureDuration_content_top_btn{
    min-height: 35px;
    margin-top: 5px;
    margin-left: 20px;
    .font{
      margin-left: 20px;font-weight: bold;font-size: 14px;
    }
    .icon{
      font-size: 14px;color: #00CC33;
    }
  }

  .featureDuration_content_table{
    width: 95%;
    margin: auto;
  }

}

</style>
